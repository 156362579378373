<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
//import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Historique message",
      items: [
        {
          text: "Message",
        },
        {
          text: "Historique",
          active: true,
        },
      ],
      historique: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100,250,500],
      filter: null,
      filterOn: [],
      sortBy: "date",
      sortDesc: false,
      fields: [
        { key: "receiver", sortable: true, label: "Message envoyé à" },
        { key: "typeMessage", sortable: true, label: "Type de message" },
        { key: "subject", sortable: true, label: "Sujet" },
        { key: "text", label: "Contenu du message" },
        { key: "date", sortable: true, label: "Date" },
      ],
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDataMessages: [],
      recherche:"",
      parBon:500


    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.historique.length;
    },
  },
  async mounted() {
    //Chargement des données
    const Data = await apiRequest("GET", "admin/messages", undefined, false);

    if (Data && Data.data) {
     
      this.totalValue = Data.data.total
      this.totalRows =  Data.data.total
      const historiqueTable = Data.data.msg.map((historiqueMsg) => {
        return {
          id: historiqueMsg.id,
          receiver: historiqueMsg.receiver,
          typeMessage:
            historiqueMsg.typeMsg == 0
              ? "Notification"
              : historiqueMsg.typeMsg == 1
              ? "Mail"
              : historiqueMsg.typeMsg == 2
              ? "Notification & Mail"
              : "",
          subject: historiqueMsg.subjectMsg,
          text: historiqueMsg.textMsg,
          date: new Date(historiqueMsg.dateMsg).toLocaleString("fr-FR"),
        };
      });
      this.historique = historiqueTable;
    }
    // Set the initial number of items
    this.totalValue = this.historique.length;
    this.totalRows = this.historique.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async userSearch() {
     
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/messages-recherche",
        {mots: this.recherche},
        false
      );
      if (dataSearch && dataSearch.data) {
     
      const historiqueTable = dataSearch.data.map((historiqueMsg) => {
        return {
          id: historiqueMsg.id,
          receiver: historiqueMsg.receiver,
          typeMessage:
            historiqueMsg.typeMsg == 0
              ? "Notification"
              : historiqueMsg.typeMsg == 1
              ? "Mail"
              : historiqueMsg.typeMsg == 2
              ? "Notification & Mail"
              : "",
          subject: historiqueMsg.subjectMsg,
          text: historiqueMsg.textMsg,
          date: new Date(historiqueMsg.dateMsg).toLocaleString("fr-FR"),
        };
      });
      this.newDataMessages = historiqueTable;
      this.userChargeLoader = false;

      this.totalRows = historiqueTable.length;
    }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
     
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
     
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
     
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
     
      this.actif(this.startValue, this.endValue);
    },

    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/messages?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
     
      const historiqueTable = data.data.map((historiqueMsg) => {
        return {
          id: historiqueMsg.id,
          receiver: historiqueMsg.receiver,
          typeMessage:
            historiqueMsg.typeMsg == 0
              ? "Notification"
              : historiqueMsg.typeMsg == 1
              ? "Mail"
              : historiqueMsg.typeMsg == 2
              ? "Notification & Mail"
              : "",
          subject: historiqueMsg.subjectMsg,
          text: historiqueMsg.textMsg,
          date: new Date(historiqueMsg.dateMsg).toLocaleString("fr-FR"),
        };
      });
      this.newDataMessages = historiqueTable;
      this.userChargeLoader = false;
      this.totalRows = historiqueTable.length;
    }
    },





    
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="containerDot" v-if="historique.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
         

            <div class="row d-flex justify-content-between align-items-center mt-4">
              <div class="p-3">
                <h4 class="card-title">Total de messages : {{ totalRows }}</h4>
              </div>
              <!-- Search -->
              <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="recherche"
                        @keyup.enter="userSearch"
                      />
                    </div>
                  </div>
              <!-- End search -->
            </div>


            <div
                  class="row d-flex justify-content-end align-items-center p-2"
                >
                <div>
                  
                </div>
                  <transition mode="out-in">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <li>
                          <span class="interval"> {{ startValue }}</span> -
                          <span class="interval">{{ endValue }}</span> sur
                          {{ totalValue }}
                        </li>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <li>
                          <button
                            class="myBouton"
                            @click="firstVal()"
                            :disabled="startValue == 1"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Prem"
                          >
                            <ion-icon name="play-skip-back-outline"></ion-icon>
                          </button>
                          <button
                            type="button"
                            class="myBouton"
                            @click="decrement()"
                            :disabled="endValue == parBon"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Préc"
                          >
                            <ion-icon name="chevron-back-outline"></ion-icon>
                          </button>
                          <button
                            class="myBouton"
                            @click="increment()"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Suiv"
                            :disabled="endValue >= totalValue"
                          >
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                          </button>
                          <button
                            class="myBouton"
                            @click="incrementToLast()"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Dern"
                            :disabled="endValue >= totalValue"
                          >
                            <ion-icon
                              name="play-skip-forward-outline"
                            ></ion-icon>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </transition>
                </div>
                
           

                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="newDataMessages.length!=0?newDataMessages:historique"
                    :fields="fields"
                    responsive="sm"
               
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                  <template v-slot:cell(text)="row">
                      <div
                        class="d-flex justify-content-center"
                       
                      >
                    <p v-html="row.value"> </p>
                    </div>
                    </template>
                  </b-table>

               
                </div>
                <div class="row">
                  <div class="col">
                  <!--   <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
            
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div> -->
                    <transition mode="out-in">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <li>
                          <span class="interval"> {{ startValue }}</span> -
                          <span class="interval">{{ endValue }}</span> sur
                          {{ totalValue }}
                        </li>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <li>
                          <button
                            class="myBouton"
                            @click="firstVal()"
                            :disabled="startValue == 1"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Prem"
                          >
                            <ion-icon name="play-skip-back-outline"></ion-icon>
                          </button>
                          <button
                            type="button"
                            class="myBouton"
                            @click="decrement()"
                            :disabled="endValue == parBon"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Préc"
                          >
                            <ion-icon name="chevron-back-outline"></ion-icon>
                          </button>
                          <button
                            class="myBouton"
                            @click="increment()"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Suiv"
                            :disabled="endValue >= totalValue"
                          >
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                          </button>
                          <button
                            class="myBouton"
                            @click="incrementToLast()"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Dern"
                            :disabled="endValue >= totalValue"
                          >
                            <ion-icon
                              name="play-skip-forward-outline"
                            ></ion-icon>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </transition>

                  </div>
                </div>
           
          </div>
        </div>
      </div>
    </div>

    <!-- -->
 



    <!-- -->
  </Layout>
</template>

<style>
.custom-checkbox {
  width: 35px;
  height: 30px;
  cursor: pointer;
}

.custom-checkbox .checkmark {
  width: 100%;
  height: 100%;
  border: 2px solid #222;
  display: inline-block;
  border-radius: 3px;
  background: #009500
    url(https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png)
    center/1250% no-repeat;
  transition: background-size 0.25s cubic-bezier(0.87, -0.16, 0.01, 1.35);
}

.custom-checkbox input:checked + .checkmark {
  background-size: 60%;
  transition: background-size 0.25s ease;
}
.custom-checkbox input {
  display: none;
}


thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}


ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}


.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}

.containerDotContainer{
  position: relative;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

</style>
